export default [
  {
    src: "/distressedLadies.png",
    alt: "Distressed Ladies Logo",
    url: "http://distressed-ladies.com/de/",
    offerUrl: "/offers/distressedLadies",
    offerType: "internal",
    offers: [
      "Tutorials für Krisen Management, Insolvenz Management und Restrukturierung",
    ],
  },
  {
    src: "/acronis.png",
    alt: "Acronis Logo",
    url: "https://www.acronis.com/de-de/",
    offerUrl:
      "https://promo.acronis.com/EUROPE-FY20-Q2-DACH-DE-Covid19_Endcustomer_Campaign.html",
    offerType: "external",
    offers: [
      "Cyber Protected Backup & Storage Lösung",
      "File Sharing Cloud Lösung",
      "Collaboration Lösung",
    ],
  },
  {
    src: "/isg.png",
    alt: "isg Logo",
    url: "https://isg-one.com/DE",
    offerUrl: "/offers/isg",
    offerType: "internal",
    offers: [
      "Cost take-out Quick Check – Kurzfristige Kostensenkungsmaßnahmen zur Schaffung von Liquidität",
      "Business Continuity Quick Check zur Absicherung des laufenden IT-Betriebes",
      "Robotic process automation (RPA) Pilotierung – Kurzfristige Automatisierung kritischer Geschäftsprozesse",
      "Bedarfsanalyse und Abgleich mit Leistungsangeboten von IT-Dienstleistern für Corona-spezifische Bedarfe",
    ],
  },
  {
    src: "/beceptum.png",
    alt: "Beceptum International Logo",
    url: "https://www.beceptum.com/",
    offerUrl: "/offers/beceptum",
    offerType: "internal",
    offers: [
      "Quick check und Coaching für Investoren Briefings",
      "Quick check und Coaching für Geschäftspläne",
      "Coaching und Brainstorming Sessions: Eco-Systeme und strategische Partnerschaften in der Krise als Wachstumstreiber nach der Krise",
      "Coaching und Brainstorming Sessions: Politische Interessensvertretung in Zeiten der Krise",
    ],
  },
  {
    src: "/knooing.png",
    alt: "knooing Logo",
    url: "https://knooing.de/",
    offerUrl: "/offers/knooing",
    offerType: "internal",
    offers: ["Matchmaking zwischen Anbieter- und Anwenderseite"],
  },
  {
    src: "/trainerwerk.png",
    alt: "Tarinerwerk Logo",
    url: "http://trainerwerk.net/",
    offerUrl: "/offers/trainerwerk",
    offerType: "internal",
    offers: [
      "Supervision / Austausch und Beratung zu aktuellen Herausforderungen und Chancen in Zeiten virtuellen Arbeitens",
      "Zusammenarbeit in virtuellen Teams",
      "Führung auf Distanz",
      "Cool Down im Homeoffice",
    ],
  },
  {
    src: "/huebner.png",
    alt: "Hübner Management GmbH Logo",
    url: "mailto:britta.huebner@huebner-interim.de",
    offerUrl: "/offers/huebner",
    offerType: "internal",
    offers: [
      "Quick check und Coaching für Liquiditätsplanung und Zahlungsströme in der Krise",
    ],
  },
  {
    src: "/christ_and_company.png",
    alt: "Christ & Company Logo",
    url: "https://christundcompany.com/",
    offerUrl: "https://christundcompany.com/corona",
    offerType: "external",
    offers: [
      "Zusammenstellung der monetären Hilfsangebote von Bund und Ländern",
    ],
  },
  {
    src: "/mission_female.png",
    alt: "Mission Female Logo",
    url: "https://www.missionfemale.com/#mission",
    offerUrl: "/offers/missionFemale",
    offerType: "internal",
    offers: [
      "Zugang zum Netzwerk von Top-Entscheiderinnen von Unternehmen im deutschsprachigen Raum",
    ],
  },
  {
    src: "/adobe.png",
    alt: "Adobe Logo",
    url: "https://www.adobe.com/de/",
    offerUrl: "https://www.adobe.com/de/covid-19-response.html",
    offerType: "external",
    offers: [
      "Tipps und Übersicht über kostenlose Adobe-Angebote, die helfen das Geschäft am Laufen zu halten",
      "Tools, die das digitalisierte, gemeinsame Arbeiten auf jedem Gerät ermöglichen, kostenlos testen",
      "Überblick über kreative Lösungen & maßgeschneiderte Lösungen für individuelle Branchen",
      "Kontinuierliche Aktualisierung der zur Verfügung gestellten, kostenlosen Adobe-Programme",
    ],
  },
  {
    src: "/digatus.png",
    alt: "digatus Logo",
    url: "https://www.digatus.de/",
    offerUrl: "/offers/digatus",
    offerType: "internal",
    offers: [
      "Beratung zu Arbeitskultur, Kommunikation und Infrastruktur im Home-Office, mit Fokus auf Office 365 und Microsoft Teams",
    ],
  },
  {
    src: "/how_to_okr.png",
    alt: "how-to-okr.com Logo",
    url: "https://www.how-to-okr.com/",
    offerUrl: "https://calendly.com/hannesokr/free-30min-meet-up-consultation",
    offerType: "external",
    offers: ["„Ask me anything“ rund um das Thema Remote Leadership"
    ],
  },
  {
      src: "/metafinanz.png",
      alt: "metafinanz Logo",
      url: "https://www.metafinanz.de",
      offerUrl: "/offers/metafinanz",
      offerType: "internal",
      offers: [
        "Mit AppNavi Mitarbeiter direkt in Anwendungen trainieren und jede Software sofort anwenden",
        "Routen und News verwenden, um Nutzer zielgruppenspezifisch durch Anwendungsfälle zu führen und zu informieren",
        "Mitarbeiter im Home Office digital anleiten und Arbeitsfähigkeit sicherstellen",
        
    ],
  },
    {
        src: "/KBC.png",
        alt: "KBC Logo",
        url: "https://kbc-consultants.com/",
        offerUrl: "https://kbc-consultants.com/wp-content/uploads/KBC_Corona_StartUp_Masterplan.pdf",
        offerType: "external",
        offers: [
          "Unterstützung zum schnellen und sicheren Start der Werksproduktion nach dem Lockdown",
          "12 Corona-Tools zur schnellen Wiederaufnahme der Produktion",
          "BAFA geförderter1-Tages-Workshop",
          
        ],
    },
]
