import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../components/title"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import styles from "../css/showroom.module.css"
import SEO from "../components/seo"
import offers from "../constants/offers"

const Showroom = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Showroom" />
      <StyledHero img={data.showroomBcg.childImageSharp.fluid} />
      <section className={styles.showroom}>
        <Title title="Show" subtitle="Room" />
        <div className={styles.showroomCenter}>
          <h4>Übersicht über direkt abrufbare Pro Bono Service-Angebote</h4>
          {offers.map((item, index) => {
            return (
              <div key={index} className={styles.showroomGrid}>
                <article className={styles.showroomColumn}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img
                      className={styles.partnerLogo}
                      src={item.src}
                      alt={item.alt}
                    />
                  </a>
                </article>
                <article className={styles.showroomColumn}>
                  <ul>
                    {item.offers.map((subitem, index) => {
                      return <li key={index}>{subitem}</li>
                    })}
                  </ul>
                </article>
                <article className={styles.showroomColumn}>
                  {(() => {
                    switch (item.offerType) {
                      case "external":
                        return (
                          <a
                            href={item.offerUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-primary"
                          >
                            Zum Angebot
                          </a>
                        )
                      default:
                        return (
                          <AniLink
                            fade
                            to={item.offerUrl}
                            className="btn-primary"
                          >
                            Zum Angebot
                          </AniLink>
                        )
                    }
                  })()}
                </article>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    showroomBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Showroom
